<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="9" md="8" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                         <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="fiscal_year_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('birtan.fiscal_year') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="subsidySetup.fiscal_year_id"
                                                    :options="fiscalYearList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="subsidy type" vid="subsidy_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="subsidy_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('bsri_config.subsidy_type') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subsidySetup.subsidy_type_id"
                                                :options="subsidyTypeList"
                                                id="subsidy_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="season_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('dae_config.season_name') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subsidySetup.season_id"
                                                :options="seasonList"
                                                id="season_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Subsidy Name (En)" vid="subsidy_name" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="subsidy_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bsri_config.subsidy_name')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="subsidy_name"
                                                v-model="subsidySetup.subsidy_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Subsidy Name (Bn)" vid="subsidy_name_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="subsidy_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bsri_config.subsidy_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="subsidy_name_bn"
                                                v-model="subsidySetup.subsidy_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Description (En)" vid="description" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="description"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bsri_config.description_en')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="description"
                                                v-model="subsidySetup.description"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="description_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bsri_config.description_bn')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="description_bn"
                                                v-model="subsidySetup.description_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidySetupStore, subsidySetupUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.subsidyData()
            this.subsidySetup = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            subsidySetup: {
                fiscal_year_id: '0',
                subsidy_type_id: '0',
                season_id: '0',
                subsidy_name: '',
                subsidy_name_bn: '',
                description: '',
                description_bn: '',
                amount: '00'
            }
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        subsidyTypeList: function () {
            const dataList = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        seasonList: function () {
            return this.$store.state.incentiveGrant.commonObj.seasonSetupList.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        fiscalYearList: function () {
            const dataList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    watch: {
        'subsidySetup.subsidy_type_id': function (newValue, OldValue) {
            this.subsidyList = this.getSubsidyList(newValue)
        }
    },
    methods: {
        getSubsidyList (subsidyTypeId) {
            const dataList = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        subsidyData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${subsidySetupUpdate}/${this.id}`, this.subsidySetup)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subsidySetupStore, this.subsidySetup)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
